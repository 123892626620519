// prefer default export if available

const preferDefault = m => m && m.default || m
exports.components = {
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-1-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/17e28f18-9950-51e5-a6de-78beac961aeb.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-1-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-137-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/2502e42b-c8ab-5cbb-9d80-d33f948f3d28.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-137-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-103-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/ca85c454-5959-596d-b94c-69331e523755.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-103-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-97-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/ddd5b4c2-6a6e-52d0-afa8-74686337c9f8.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-97-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-94-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/ad54210e-6738-5b11-9a60-a107a135d86d.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-94-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-39-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/cb43ed1f-9f3d-5a6f-8b78-4bff37b4839c.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-39-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-35-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/db1c9148-291c-5046-a42c-775e118bfdbf.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-35-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-31-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/e1c2d9bc-3652-5f1b-bc0b-2f21dada7ede.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-31-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-27-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/29cd768a-a06b-5e0b-ad01-2938c495f325.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-27-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-15-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/2d2720c1-8ee5-540f-9525-e077f482db87.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-15-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-534-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/b4f50ee7-6380-51c9-b6f9-72dfc63f5ae7.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-534-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-532-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/ef3ff84b-6f3f-5744-84c0-0939488ae964.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-532-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-530-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/14c32d35-bd76-57df-94df-87f01f3ee13f.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-530-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-528-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/d48def2c-45c0-5f5d-bee7-7ab5017b0c9a.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-528-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-526-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/0e79d749-17d2-57bb-9bbf-ea9d00224276.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-526-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-524-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/e98e98e8-a6d9-5971-aac0-0574e578006e.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-524-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-522-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/b16ad3dc-5e32-5cae-b8aa-78f98e4a9de7.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-522-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-520-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/fb060d8f-a550-51b6-9f0a-b2c7ff72ce4e.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-520-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-518-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/093dcb53-7ccb-57c1-b13d-9ddcc8fe5a62.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-518-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-536-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/5de3f089-9a03-5d41-be49-00e4e04f994d.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-536-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-516-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/88828641-6065-5111-8fad-374eb936e330.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-516-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-514-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/a9d5c992-1cd7-531f-964c-ea952b09883f.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-514-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-408-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/dcbb7918-a550-5175-92fb-f685e8389f0a.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-408-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-406-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/45f5c578-bf40-57a6-a152-297987c6eae2.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-406-js" */).then(preferDefault),
  "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-403-js": () => import("/home/ubuntu/michelatsch-website/frontend/.cache/gatsby-plugin-graphql-component/components/cb45ef8f-bd36-5599-801c-7fec47944a58.js" /* webpackChunkName: "component---cache-fragments-gatsby-theme-wordpress-gutenberg-blocks-403-js" */).then(preferDefault)
}

